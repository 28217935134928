import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import { styleEstado } from "../utils/state.uitls";
import surveyResponseService from "../services/survey.service";
import { viewTypes } from "../common/types/views.type";
import { ShowView } from "../common/components/ShowView";

export const LlamadaDetailCompnent = ({
  llamado,
  campanaUserId,
  encuestaId,
  surveyResponseId,
  surveyResponseNumber,
}) => {
  const { vnDetalleLlamado: viewName, vfVerAudio, vfVerAudioCPN } = viewTypes;
  const userName = localStorage.getItem("userName");

  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioContextRef = useRef<AudioContext | null>(null);
  const audioBufferRef = useRef<AudioBuffer | null>(null);
  const sourceRef = useRef<AudioBufferSourceNode | null>(null);

  const showAudio = ShowView(vfVerAudio, viewName);
  const showAudioCPN = ShowView(vfVerAudioCPN, viewName);
  const [mostrarAudioPlus, setMostrarAudioPlus] = useState(null);

  const checkVisibilidad = () => {
    let _mostrarAudioPlus = true;

    if (
      (llamado.estado.toLowerCase() === "pendiente de llamar" ||
        llamado.estado.toLowerCase() === "contestador" ||
        llamado.estado.toLowerCase() === "no se pudo contactar" ||
        llamado.estado.toLowerCase() === "no se puede contactar") &&
      !showAudioCPN
    ) {
      _mostrarAudioPlus = false;
    }
    setMostrarAudioPlus(_mostrarAudioPlus);
  };

  useEffect(() => {
    checkVisibilidad();
  }, []);

  useEffect(() => {
    const fetchAudio = async () => {
      try {
        const response = await surveyResponseService.obtenerAudioFile(
          campanaUserId,
          encuestaId,
          surveyResponseId,
          llamado.uniqueId + surveyResponseNumber,
          llamado.rangoDay,
          llamado.rangoFrom,
          llamado.rangoTo,
          userName,
          viewName,
          vfVerAudio
        );
        const arrayBuffer = response.data;
        const blob = new Blob([arrayBuffer], { type: "audio/wav" });
        setAudioBlob(blob);
        setAudioUrl(URL.createObjectURL(blob));
      } catch (error) {
        console.error("Error al obtener el archivo de audio:", error);
      }
    };

    fetchAudio();

    return () => {
      if (audioContextRef.current) {
        audioContextRef.current.close();
      }
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, []);

  useEffect(() => {
    if (audioBlob) {
      const initAudioContext = async () => {
        const arrayBuffer = await audioBlob.arrayBuffer();
        const audioContext = new window.AudioContext();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

        audioContextRef.current = audioContext;
        audioBufferRef.current = audioBuffer;
      };

      initAudioContext();
    }
  }, [audioBlob]);

  const handlePlay = () => {
    if (audioBufferRef.current && audioContextRef.current) {
      const audioContext = audioContextRef.current;
      const source = audioContext.createBufferSource();
      source.buffer = audioBufferRef.current;
      source.connect(audioContext.destination);
      source.start(0);

      sourceRef.current = source;
      setIsPlaying(true);

      source.onended = () => {
        setIsPlaying(false);
      };
    }
  };

  const handleStop = () => {
    if (sourceRef.current) {
      sourceRef.current.stop();
      sourceRef.current.disconnect();
      sourceRef.current = null;
      setIsPlaying(false);
    }
  };

  return (
    <div className="llamada-component">
      <div className="container">
        <div className="row">
          <Button
            icon="pi pi-calendar"
            className="p-button-rounded p-button-info mr-1"
          />
          <div className="text">
            <span className="label">Fecha: </span>
            <span className="data">{llamado.fecha}</span>
          </div>
        </div>

        <div className="row">
          <Button
            icon="pi pi-clock"
            className="p-button-rounded p-button-warning mr-1"
          />
          <div className="text">
            <span className="label">Hora: </span>
            <span className="data">{llamado.hora}</span>
          </div>
        </div>

        <div className="row">
          <Button
            icon="pi pi-history"
            className="p-button-rounded p-button-success mr-1"
          />
          <div className="text">
            <span className="label">Duración:</span>
            <span className="data">{llamado.duracion}</span>
          </div>
        </div>
      </div>

      <div className="footer">
        <span
          className={`customer-badge status-${styleEstado(
            llamado.estado
          )} text-status estado-llamado`}
        >
          {llamado.estado}
        </span>

        {llamado.mostrarAudio && showAudio && mostrarAudioPlus && (
          <div className="audio-footer">
            <i className="icon-sound pi pi-volume-up"></i>
            <button
              onClick={handlePlay}
              disabled={isPlaying}
              style={{ opacity: isPlaying ? 0.6 : 1 }}
            >
              <i className="pi pi-play"></i>
            </button>

            <button
              onClick={handleStop}
              disabled={!isPlaying}
              style={{ opacity: !isPlaying ? 0.6 : 1 }}
            >
              <i className="pi pi-stop"></i>
            </button>

            {audioUrl && (
              <a
                className="rep"
                href={audioUrl}
                download={`${surveyResponseNumber}.wav`}
              >
                <button>
                  <i className="pi pi-download"></i>
                </button>
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
