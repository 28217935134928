import { useState, useEffect } from "react";
import surveyResponseService from "../services/survey.service";
import { Skeleton } from "primereact/skeleton";
import { viewTypes } from "../common/types/views.type";
import { ShowView } from "../common/components/ShowView";
import { Estados } from "./Estados";
import { CsvDataComponent } from "./CsvDataComponent";
import { LlamadaDetailCompnent } from "./LlamadaDetailCompnent";

interface ILlamados {
  estado: string;
  duracion: number;
  fecha: string;
  hora: string;
  reintentos: number;
  audio: boolean;
  rangoDay: string;
  rangoFrom: string;
  rangoTo: string;
}

interface ISurveyResponseData {
  csvData: any;
  estadoFinal: string;
  reintentos: number;
  llamados: [ILlamados];
}

export const SurveyResponse = () => {
  const [loading, setLoading] = useState(false);
  const [surveyResponseId, setSurveyResponseId] = useState(
    sessionStorage.getItem("surveyReponseId")
  );
  const [encuestaId, setEncuestaId] = useState(
    sessionStorage.getItem("campanaId")
  );
  const [surveyResponseData, setSurveyResponseData] =
    useState<ISurveyResponseData | null>();

  const [surveyResponseNumber, setSurveyResponseNumber] = useState(
    sessionStorage.getItem("surveyReponseNumber")
  );

  const { vnDetalleLlamado: viewName, vfVerDetalleLlamado } = viewTypes;

  const campanaUserId = sessionStorage.getItem("campanaUserId");
  const userName = localStorage.getItem("userName");
  const showDetalleLamado = ShowView(vfVerDetalleLlamado, viewName);

  useEffect(() => {
    loadLazyData();
  }, []);

  let loadLazyTimeout = null;

  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      surveyResponseService
        .obtenerSurveyResponse(
          surveyResponseId,
          userName,
          viewName,
          vfVerDetalleLlamado
        )
        .then((response: any) => {
          const data = {
            csvData: response.data.csvData,
            estadoFinal: response.data.estadoFinal,
            reintentos: response.data.reintentos,
            llamados: response.data.llamados,
            uniqueId: response.data.uniqueId,
          };
          setSurveyResponseData(data);
          setLoading(false);
        })
        .catch((e: Error) => {});
    }, Math.random() * 1000 + 250);
  };

  const DivCsvData = () => {
    return (
      <div className="csv-data">
        <div>
          {surveyResponseData && (
            <Estados
              estadoFinal={surveyResponseData.estadoFinal}
              surveyReponseId={surveyResponseId}
            />
          )}
        </div>

        <div className="card">
          <ul className="widget-person-list">
            {surveyResponseData && (
              <CsvDataComponent csvData={surveyResponseData.csvData} />
            )}
          </ul>
        </div>
      </div>
    );
  };

  const DivDetailLlamada = () => {
    return (
      <div className="survey-detail">
        {surveyResponseData &&
          surveyResponseData.llamados.map((llamado) => (
            <LlamadaDetailCompnent
              key={llamado.fecha + llamado.hora}
              llamado={llamado}
              campanaUserId={campanaUserId}
              encuestaId={encuestaId}
              surveyResponseId={surveyResponseId}
              surveyResponseNumber={surveyResponseNumber}
            />
          ))}
      </div>
    );
  };

  return (
    <>
      {showDetalleLamado && (
        <>
          {loading ? (
            <div className="border-round border-1 surface-border p-4">
              <Skeleton width="100%" height="150px"></Skeleton>
              <div className="flex justify-content-between mt-3">
                <Skeleton width="4rem" height="2rem"></Skeleton>
                <Skeleton width="4rem" height="2rem"></Skeleton>
              </div>
            </div>
          ) : (
            <div className="survey-response">
              <div className="column">
                <DivCsvData />
              </div>
              <div className="column">
                <DivDetailLlamada />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
