import http from "../common/components/http-common";
class SurveyResponseService {
  obtenerSurveyResponse(
    idSurveyResponse,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
    };
    return http.get("/surveyResponse/surveyResponseDetail/" + idSurveyResponse);
  }

  obtenerAudioFile(
    idUser,
    idEncuesta,
    idSurveyResponse,
    uniqueId,
    day,
    from,
    to,
    userName: string,
    viewName: string,
    viewFunction: string
  ) {
    let config = {
      headers: {
        userName: userName,
        viewName: viewName,
        viewFunction: viewFunction,
      },
      params: { bucket: "froneus-bk2", uniqueId: uniqueId },
      responseType: "blob" as const,
    };
    return http.get(
      `surveyResponseAudio/file/${idUser}/${idEncuesta}/${idSurveyResponse}/${day}/${from}/${to}`,
      config
    );
  }
}

export default new SurveyResponseService();
